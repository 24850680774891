import ruDesk1 from './ru/Slider_Image_Desk_1.png'
import ruDesk2 from './ru/Slider_Image_Desk_2.png'
import ruDesk3 from './ru/Slider_Image_Desk_3.png'
import ruDesk4 from './ru/Slider_Image_Desk_4.png'
import ruDesk5 from './ru/Slider_Image_Desk_5.png'
import ruDesk6 from './ru/Slider_Image_Desk_6.png'
import ruMob1 from './ru/Slider_Image_Mob_1.png'
import ruMob2 from './ru/Slider_Image_Mob_2.png'
import ruMob3 from './ru/Slider_Image_Mob_3.png'
import ruMob4 from './ru/Slider_Image_Mob_4.png'
import ruMob5 from './ru/Slider_Image_Mob_5.png'
import ruMob6 from './ru/Slider_Image_Mob_6.png'
import {SCREEN_VARIANT} from "../../types/IScreen";
import {LANG} from "../../types/IUser";

export const LOTTIE_PHOTOLENTA = {
    [LANG.RU]: {
        [SCREEN_VARIANT.DESKTOP]: [ruDesk1, ruDesk2, ruDesk3, ruDesk4, ruDesk5, ruDesk6],
        [SCREEN_VARIANT.TABLET]: [ruDesk1, ruDesk2, ruDesk3, ruDesk4, ruDesk5, ruDesk6],
        [SCREEN_VARIANT.MOBILE]: [ruMob1, ruMob2, ruMob3, ruMob4, ruMob5, ruMob6],
    },
    [LANG.EN]: {
        [SCREEN_VARIANT.DESKTOP]: [ruDesk1, ruDesk2, ruDesk3, ruDesk4, ruDesk5, ruDesk6],
        [SCREEN_VARIANT.TABLET]: [ruDesk1, ruDesk2, ruDesk3, ruDesk4, ruDesk5, ruDesk6],
        [SCREEN_VARIANT.MOBILE]: [ruMob1, ruMob2, ruMob3, ruMob4, ruMob5, ruMob6],
    },
    [LANG.ES]: {
        [SCREEN_VARIANT.DESKTOP]: [ruDesk1, ruDesk2, ruDesk3, ruDesk4, ruDesk5, ruDesk6],
        [SCREEN_VARIANT.TABLET]: [ruDesk1, ruDesk2, ruDesk3, ruDesk4, ruDesk5, ruDesk6],
        [SCREEN_VARIANT.MOBILE]: [ruMob1, ruMob2, ruMob3, ruMob4, ruMob5, ruMob6],
    },
    [LANG.CN]: {
        [SCREEN_VARIANT.DESKTOP]: [ruDesk1, ruDesk2, ruDesk3, ruDesk4, ruDesk5, ruDesk6],
        [SCREEN_VARIANT.TABLET]: [ruDesk1, ruDesk2, ruDesk3, ruDesk4, ruDesk5, ruDesk6],
        [SCREEN_VARIANT.MOBILE]: [ruMob1, ruMob2, ruMob3, ruMob4, ruMob5, ruMob6],
    },
    [LANG.EG]: {
        [SCREEN_VARIANT.DESKTOP]: [ruDesk1, ruDesk2, ruDesk3, ruDesk4, ruDesk5, ruDesk6],
        [SCREEN_VARIANT.TABLET]: [ruDesk1, ruDesk2, ruDesk3, ruDesk4, ruDesk5, ruDesk6],
        [SCREEN_VARIANT.MOBILE]: [ruMob1, ruMob2, ruMob3, ruMob4, ruMob5, ruMob6],
    },
    [LANG.FA]: {
        [SCREEN_VARIANT.DESKTOP]: [ruDesk1, ruDesk2, ruDesk3, ruDesk4, ruDesk5, ruDesk6],
        [SCREEN_VARIANT.TABLET]: [ruDesk1, ruDesk2, ruDesk3, ruDesk4, ruDesk5, ruDesk6],
        [SCREEN_VARIANT.MOBILE]: [ruMob1, ruMob2, ruMob3, ruMob4, ruMob5, ruMob6],
    },

}