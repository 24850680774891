import React, {FC} from 'react';
import cn from 'classnames'
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css/effect-fade';
import SwiperCore, {A11y, Autoplay, EffectFade, Navigation, Pagination} from "swiper";
import widget1 from "../../lottie/images/widget1.webm";
import widget2 from "../../lottie/images/widget2.webm";
import widget3 from "../../lottie/images/widget3.webm";
import widget4 from "../../lottie/images/widget4.webm";
import widget5 from "../../lottie/images/widget5.webm";
import widget6 from "../../lottie/images/widget6.webm";
import widget7 from "../../lottie/images/widget7.webm";
import widget8 from "../../lottie/images/widget8.webm";
import widgetBG1 from "../../lottie/images/bg/widget1.mp4";
import widgetBG2 from "../../lottie/images/bg/widget2.mp4";
import widgetBG3 from "../../lottie/images/bg/widget3.mp4";
import widgetBG4 from "../../lottie/images/bg/widget4.mp4";
import widgetBG5 from "../../lottie/images/bg/widget5.mp4";
import widgetBG6 from "../../lottie/images/bg/widget6.mp4";
import css from './Lottie.module.scss';
import Icon from "../Icon";
import {ICON_VARIANT} from "../../assets/svg";
import {useAppSelector} from "../../hooks/redux";
import {SCREEN_VARIANT} from "../../types/IScreen";
import {THEME} from "../../types/IUser";
import {SIDS, SUBSCRIBE, TELEGRAM} from "../../constants";
import LottieNews from "./LottieNews";
import {useTranslate} from "../../hooks/useTranslate";
import {useBodyClass} from "../../hooks/useBodyClass";
import Telegram from "../Telegram";
import LottieList from "./LottieList/LottieList";
import LottieItem from "./item";
import {LOTTIE_PHOTOLENTA} from "../../lottie/slider";

SwiperCore.use([Autoplay, EffectFade, Navigation, Pagination, A11y]);

const LottiePage:FC = () => {
    const { lang } = useAppSelector(state => state.auth);
    const windowScreen = useAppSelector(state => state.screen.screen);
    const {theme} = useAppSelector(state => state.auth);
    const translate = useTranslate();
    const photolenta = LOTTIE_PHOTOLENTA[lang][windowScreen]

    const isSafariBrowser = () => {
        const ua = navigator.userAgent.toLowerCase();

        return ua.includes('safari') && !ua.includes('chrome') && !ua.includes('edge');
    };

    const isSafari = isSafariBrowser()

    useBodyClass(isSafari ? 'lottie' : 'lottie-transparent');

    return <>
        <div className={css.relative}>
            <div className={cn(css.section, css.main)}>
                <div className={css.info}>
                    <div className={css.title}>
                        Мультимедийный новостной терминал
                    </div>
                    <div className={css.description}>
                        Выбор из оперативных и архивных новостей, фото и видео на единой мультимедийной платформе
                    </div>
                    <div className={css.buttons}>
                        <a href={SUBSCRIBE[lang].link} target={"_blank"} className={css.btn}>Оформить подписку</a>
                        <a href={TELEGRAM[lang].link} target={"_blank"} className={cn(css.btn, css.outline)}><span
                            className={css.btnIcon}><Icon
                            name={ICON_VARIANT.TELEGRAM}/></span>Читать в Телеграм</a>
                    </div>
                </div>
                <div className={css.media}>
                    <div className={css.macbook}>
                        <div className={css.lottieNews}>
                            <LottieNews/>
                        </div>
                        <div className={css.lottieTelegram}>
                            {SIDS[lang].INDEX
                                ? <LottieList/>
                                : <Telegram/>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className={css.ellipse_1}/>*/}
            {/*<div className={css.ellipse_2}/>*/}
        </div>
        <LottieItem
            classNames={[css.reverse, css.h401]}
            title={'Управление контентом'}
            description={'Вам доступны группировка новостей по темам и сюжетам, сложный поиск по фразам, настраиваемые фильтры и избранное'}
            video={isSafari ? widgetBG1 : widget1}/>
        <LottieItem
            classNames={[css.h379]}
            title={'Картина дня от редакции'}
            description={'Самые главные новости часа, актуальные тенденции дня в мультимедийном формате'}
            video={isSafari ? widgetBG2 : widget2}/>
        <LottieItem
            classNames={[css.reverse, css.h426]}
            title={'Управление новостями'}
            description={'Возможность делиться новостями в соцсетях и мессенджерах. История скачиваний сохраняется'}
            video={isSafari ? widgetBG3 : widget3}/>
        <LottieItem
            classNames={[css.h395]}
            title={'Мультимедийный контент'}
            description={'Впервые для подписчиков доступна синергия текста, фото и видео в новости'}
            video={isSafari ? widgetBG4 : widget4}/>
        <LottieItem
            classNames={[css.reverse, css.h336]}
            title={' Обучаемый поиск'}
            description={'Для формирования релевантной выдачи. Возможность создания собственных лент'}
            video={isSafari ? widgetBG5 : widget5}/>
        <LottieItem
            classNames={[css.h480]}
            title={'Доступность на мобильных'}
            description={'Адаптированная версия с полным функционалом для мобильных устройств'}
            video={isSafari ? widgetBG6 : widget6}/>

        <div className={cn(css.section, css.tag)}>
            <div className={css.title}>
                Больше преимуществ в новой версии
            </div>
            <div className={css.slider}>
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={SCREEN_VARIANT.DESKTOP === windowScreen ? 24 : 16}
                    speed={300}
                >
                    <SwiperSlide>
                        <div className={css.slide}>
                            <div className={css.slideIcon}><Icon name={ICON_VARIANT.MOBILE}/></div>
                            <div className={css.slideTitle}>Больше контента</div>
                            <div className={css.slideDescription}>Впервые в истории — доступ для читателей к части
                                закрытого контента
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={css.slide}>
                            <div className={css.slideIcon}><Icon name={ICON_VARIANT.MESSAGE}/></div>
                            <div className={css.slideTitle}>Интеграция</div>
                            <div className={css.slideDescription}>Интеграция с новым Telegram-ботом и Медиабанком РИА
                                Новости
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={css.slide}>
                            <div className={css.slideIcon}><Icon name={ICON_VARIANT.MANUAL}/></div>
                            <div className={css.slideTitle}>Руководство</div>
                            <div className={css.slideDescription}>Подробная справка по функционалу с анимированными
                                иллюстрациями
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
        <div className={cn(css.section, css.news)}>
            <div className={css.title}>
                Новостные ленты — поток новостей из любой точки мира
            </div>
            <div className={css.newsList}>
                <div className={css.newsItem}>
                    <div className={css.newsTitle}>
                        На русском языке
                    </div>
                    <div className={css.newDescription}>
                        1800+ новостей в сутки на русском языке 24/7 от корреспондентской сети в 120+ городах
                    </div>
                </div>
                <div className={css.newsItem}>
                    <div className={css.newsTitle}>
                        На других языках
                    </div>
                    <div className={css.newDescription}>
                        1300+ новостей в сутки на английском, арабском, фарси, испанском и китайском языках 24/7
                    </div>
                </div>
            </div>
            <div className={css.newsBtn}>
                <a href={'/'} className={cn(css.btn, css.light)}>Узнать подробнее</a>
            </div>
        </div>

        <div className={cn(css.section, css.newsMedia)}>
            <div className={css.newsMediaWrapper}>
                <div className={css.newsMediaImg}>
                    <Swiper
                        effect="fade"
                        autoplay={{
                            delay: 1000
                        }}
                        speed={300}
                    >
                        {photolenta?.map(item =>
                            <SwiperSlide>
                                <img src={item} alt=""/>
                            </SwiperSlide>
                        )}
                    </Swiper>
                    {/*<LottieVideo video={windowScreen === SCREEN_VARIANT.DESKTOP ? widget8 : widget7}/>*/}
                    {/*<img src={widget8} alt=""/>*/}
                </div>
            </div>
        </div>

        <div className={css.footer}>
            <div className={css.footerWrapper}>
                <div className={css.footerLogo}>
                    {theme === THEME.RIA
                        ? <Icon name={ICON_VARIANT.LOGO}/>
                        : <Icon name={ICON_VARIANT.LOGO_SPUTNIK}/>
                    }
                </div>
                <div className={css.footerInfo}>
                    <div className={css.footerList}>
                        {translate.footer.tags.map((item, i) =>
                            <a key={i} href={item.link} className={css.footerListItem}>{item.title}</a>
                        )}
                    </div>
                    <div className={css.copyright}>© 2025 МИА «Россия сегодня»</div>
                </div>
            </div>
        </div>
    </>
}

export default LottiePage